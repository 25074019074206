import React from "react";
import { ChevronLeft, Diamond, Info } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { Separator } from "../components/ui/separator";
import { Textarea } from "../components/ui/textarea";
import {  CardHeader } from "../components/ui/card";
import {  Star } from "lucide-react";

function PaymentPage() {
  return (
    <div className="min-h-screen bg-white">
      <main className="container max-w-6xl md:w-[900px] mx-auto px-4 py-6 space-y-6">
        {/* Header */}
        <div className="flex items-center  pt-[100px]">
          <Button variant="ghost" size="icon" className="mr-2">
            <ChevronLeft className="h-5 w-5" />
          </Button>
          <h1 className="text-xl font-semibold">Payment</h1>
        </div>
<div className="flex flex-col md:flex-row md:w-[1000px]">
        {/* Payment Notice */}
        <div className="md:w-[40%]">
        <Card >
          <CardContent className="p-4 flex gap-3">
            <div className="w-8 h-8 rounded-full bg-red-100 flex items-center justify-center">
              <Info className="h-5 w-5 text-red-600" />
            </div>
            <p className="text-sm">
              Your payment is securely held in Airbnb escrow account and only released after you've checked in.
            </p>
          </CardContent>
        </Card>

        {/* Property Details */}
        <div className="flex gap-4">
          <img
            src="/placeholder.svg"
            alt="Historical Center Wooden Studio"
            className="rounded-lg object-cover w-24 h-24"
          />
          <div>
            <h2 className="font-medium">Historical Center Wooden Studio</h2>
            <div className="flex items-center gap-1 text-sm text-gray-600 mt-1">
              <span>★ 4.96 (231)</span>
              <span>•</span>
              <Diamond className="h-4 w-4" />
              <span>Superhost</span>
            </div>
          </div>
        </div>

        {/* Trip Details */}
        <div className="space-y-4">
          <h3 className="font-semibold">Your trip</h3>
          {[
            { label: "Dates", value: "Jan 16–17, 2025" },
            { label: "Guests", value: "1 guest" }
          ].map((item, index) => (
            <div className="flex justify-between" key={index}>
              <div>
                <p className="font-medium">{item.label}</p>
                <p className="text-sm text-gray-600">{item.value}</p>
              </div>
              <Button variant="ghost" size="sm">Edit</Button>
            </div>
          ))}
        </div>
    </div>
    <ListingCard/>
</div>

        {/* Price Details */}
        <div className="space-y-4">
          <h3 className="font-semibold">Price details</h3>
          {[
            { label: "145.00 lei × 1 night", value: "145.00 lei" },
            { label: "Airbnb service fee", value: "24.36 lei" }
          ].map((item, index) => (
            <div className="flex justify-between" key={index}>
              <span>{item.label}</span>
              <span>{item.value}</span>
            </div>
          ))}
          <Separator />
          <div className="flex justify-between font-semibold">
            <span>Total (RON)</span>
            <span>169.36 lei</span>
          </div>
        </div>

        {/* Payment Method */}
        <div className="space-y-4">
          <h3 className="font-semibold">Payment Method</h3>
          <p className="text-sm">
            Payment for this reservation is processed via bank transfer.
          </p>
          <div className="bg-gray-50 p-4 rounded-lg space-y-1 text-sm">
            <p className="font-medium">Bank Transfer Details</p>
            <p>RECEIVER NAME: AIRBNB INC.</p>
            <p>IBAN: BE49338229394894842938</p>
            <p>SWIFT/BIC Code: BE94983</p>
            <p>Reference number: BNB4948334</p>
          </div>
        </div>

        {/* Message to Host */}
        <div className="space-y-4">
          <h3 className="font-semibold">Write a message to the host</h3>
          <Textarea
            placeholder="Example: 'Hi Tina, my partner and I are going to a friend's wedding and your place is right down the street.'"
            className="min-h-[100px]"
          />
        </div>

        {/* Request to Book Button */}
        <Button className="w-[140px]" size="lg">
          Request to book
        </Button>

        {/* Additional Information */}
        <p className="text-xs text-gray-500 text-center">
          Your reservation won't be confirmed until the Host accepts your request (within 24 hours). You won't be charged until then.
        </p>
      </main>
    </div>
  );
}
export default PaymentPage;

function ListingCard({
  title = "Historical Center Wooden Studio near Big Square",
  type = "Entire condo",
  rating = 4.96,
  reviews = 531,
  isSuperhost = true,
  pricePerNight = 145.0,
  serviceFee = 24.36,
  currency = "lei",
  imageUrl = "/placeholder.svg",
}) {
  const total = pricePerNight + serviceFee;

  return (
    <Card className="max-w-md md:w-[40%] mx-auto mt-8 mb-5 md:mt-0 md:mb-0">
      <CardContent className="p-4 space-y-4">
        <div className="flex gap-4">
          <div className="flex-shrink-0">
            <img
              src={imageUrl}
              alt={title}
              className="rounded-lg object-cover w-28 h-28"
            />
          </div>
          <div className="space-y-1">
            <h2 className="font-medium text-lg">{title}</h2>
            <p className="text-gray-600">{type}</p>
            <div className="flex items-center gap-1 text-sm">
              <Star className="h-4 w-4 fill-current" />
              <span>{rating}</span>
              <span className="text-gray-600">({reviews} reviews)</span>
              {isSuperhost && (
                <>
                  <span className="text-gray-600">•</span>
                  <Diamond className="h-4 w-4" />
                  <span>Superhost</span>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="pt-4 space-y-3">
          <h3 className="text-xl font-semibold">Price details</h3>

          <div className="space-y-2">
            <div className="flex justify-between">
              <span>
                {pricePerNight.toFixed(2)} {currency} × 1 night
              </span>
              <span>
                {pricePerNight.toFixed(2)} {currency}
              </span>
            </div>

            <div className="flex justify-between">
              <a href="#" className="underline">
                Airbnb service fee
              </a>
              <span>
                {serviceFee.toFixed(2)} {currency}
              </span>
            </div>

            <div className="pt-3 border-t flex justify-between font-semibold">
              <span>Total ({currency.toUpperCase()})</span>
              <span>
                {total.toFixed(2)} {currency}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}


